// Import Bootstrap styles
import 'bootstrap/dist/css/bootstrap.min.css';

// Import Rails and Turbolinks
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";


// Inizializza Bootstrap JavaScript dopo il caricamento di Turbolinks
document.addEventListener("turbolinks:load", () => {
    // Inizializza Bootstrap JavaScript
    require('jquery');
    require('popper.js');
    require('bootstrap');
    
    // Inizializza Bootstrap dropdown
    $('[data-toggle="dropdown"]').dropdown();
  });

global.toastr = require("toastr")

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import $ from "jquery"
window.$ = $
window.jQuery = $
window.Rails = Rails



document.addEventListener('turbolinks:load', function () {
  const currentPageIdentifier = window.location.pathname;
  // Verifica se la pagina corrente è quella desiderata
  if (currentPageIdentifier === "/comunications_file") {
    const selectAllCheckbox = document.querySelector('.select-all-checkbox');
    const form = document.querySelector('#mioForm');
    const selectedUserIdsField = document.querySelector('input[name="selected_user_ids"]');

    

    // Funzione per ottenere le selezioni dalla localStorage
    function getSelectionFromStorage() {
      const storedSelections = JSON.parse(localStorage.getItem('userSelections')) || {};
      console.log("lettura localStorage");
      console.log(storedSelections);
      return storedSelections[currentPageIdentifier] || [];
    }  

    // Funzione per aggiornare le selezioni nella localStorage
    function updateSelectionInStorage(selectedUserIds) {
      let storedSelections = JSON.parse(localStorage.getItem('userSelections')) || {};
      const currentPageSelections = storedSelections[currentPageIdentifier] || [];
      
      // Rimuovi gli ID duplicati dall'array delle selezioni aggiornate
      const updatedSelections = [...new Set([...currentPageSelections, ...selectedUserIds])];

      storedSelections[currentPageIdentifier] = updatedSelections;
      localStorage.setItem('userSelections', JSON.stringify(storedSelections));
      console.log("scrittura localStorage");
      console.log(storedSelections);
    }

    // Ripristina le selezioni all'avvio della pagina
    const currentPageSelections = getSelectionFromStorage();
    console.log("selezione sulla pagina corrente");
    console.log(currentPageSelections);
    const userCheckboxes = document.querySelectorAll('input[name="selected_users[]"]');
    userCheckboxes.forEach(function (checkbox) {
      checkbox.checked = currentPageSelections.includes(checkbox.value);
    });

    // Aggiungi un event listener per gestire la selezione totale
    if (selectAllCheckbox) {
      selectAllCheckbox.addEventListener('change', function () {
        const userCheckboxes = document.querySelectorAll('input[name="selected_users[]"]');
        const selectedUserIds = [];
        userCheckboxes.forEach(function (checkbox) {
          checkbox.checked = selectAllCheckbox.checked;
          if (checkbox.checked) {
            selectedUserIds.push(checkbox.value);
          }
        });
        updateSelectionInStorage(selectedUserIds);
      });
    }

    // Aggiorna le selezioni quando cambia la pagina
    userCheckboxes.forEach(function (checkbox) {
      checkbox.addEventListener('change', function () {
        const selectedUserIds = [];
        userCheckboxes.forEach(function (checkbox) {
          if (checkbox.checked) {
            selectedUserIds.push(checkbox.value);
          }
        });
        updateSelectionInStorage(selectedUserIds);

        // Rimuovi l'ID dalla localStorage se il record viene deselezionato
        if (!checkbox.checked) {
          const storedSelections = JSON.parse(localStorage.getItem('userSelections')) || {};
          const currentPageSelections = storedSelections[currentPageIdentifier] || [];
          const indexToRemove = currentPageSelections.indexOf(checkbox.value);
          if (indexToRemove !== -1) {
            currentPageSelections.splice(indexToRemove, 1);
            storedSelections[currentPageIdentifier] = currentPageSelections;
            localStorage.setItem('userSelections', JSON.stringify(storedSelections));
            console.log("Record deselezionato, localStorage aggiornata:");
            console.log(storedSelections);
          }
        }
      });
    });

    // Aggiorna il campo nascosto con gli ID degli utenti selezionati prima di inviare il modulo
    form.addEventListener('submit', function (event) {
      const allSelectedUserIds = [];
      const storedSelections = JSON.parse(localStorage.getItem('userSelections')) || {};
      Object.keys(storedSelections).forEach(page => {
        allSelectedUserIds.push(...storedSelections[page]);
      });
      selectedUserIdsField.value = JSON.stringify(allSelectedUserIds);
    });
  } else {
     // Verifica se l'oggetto userSelections nella localStorage esiste ed eliminalo se presente
    if (localStorage.getItem('userSelections')) {
      console.log("eliminazione array");
      localStorage.removeItem('userSelections');
    }
  }
});